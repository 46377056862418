<template lang="html">
  <div class="donation-form__step-content">
    <FormCheckout @checkoutNextStep="nextStep" />
  </div>
</template>

<script>
import FormCheckout from '@/components/Form/FormCheckout.vue';

export default {
  name: 'DonationFormStep4',

  components: {
    FormCheckout,
  },
  methods: {
    nextStep() {
      this.$store.commit('donationProductForm/setDonatedProductIsShow', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
