<template lang="html">
  <div class="donation-form" v-if="!donationProductForm.donatedProductIsShow">
    <div class="donation-form__steps">
      <Steps
        :steps="donationProductForm.steps"
        :currentStep="donationProductForm.currentStep"
      />
    </div>
    <DonationFormStep1 v-if="donationProductForm.currentStep === 1" />
    <DonationFormStep2 v-else-if="donationProductForm.currentStep === 2" />
    <DonationFormStep3 v-else-if="donationProductForm.currentStep === 3" />
    <DonationFormStep4 v-else-if="donationProductForm.currentStep === 4" />
  </div>
  <DonatedProduct v-else-if="donationProductForm.donatedProductIsShow" />
</template>

<script>
import Steps from '@/components/Steps/Steps.vue';
import DonationFormStep1 from './DonationFormStep1.vue';
import DonationFormStep2 from './DonationFormStep2.vue';
import DonationFormStep3 from './DonationFormStep3.vue';
import DonationFormStep4 from './DonationFormStep4.vue';
import DonatedProduct from '@/sections/DonationForm/DonatedProduct';

export default {
  name: 'DonationForm',
  components: {
    Steps,
    DonationFormStep1,
    DonationFormStep2,
    DonationFormStep3,
    DonationFormStep4,
    DonatedProduct,
  },
  computed: {
    donationProductForm() {
      return this.$store.state.donationProductForm;
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('donationProductForm/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
