<template lang="html">
  <div class="donation-form__step-content">
    <FormData @submitForm="submitForm" @cancelSubmit="cancelSubmit" />
  </div>
</template>

<script>
import FormData from '@/components/Form/FormData.vue';

export default {
  name: 'DonationFormStep2',
  components: {
    FormData,
  },
  methods: {
    submitForm() {
      this.$store.commit('donationProductForm/setPassedStep', 2);
      this.$store.commit('donationProductForm/setCurrentStep', 3);
    },
    cancelSubmit() {
      this.$store.dispatch('donationProductForm/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
