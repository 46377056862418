<template lang="html">
  <img
    class="donation-form__background-picture"
    src="@/assets/images/building1.png"
    alt="Building"
  />
  <div class="donation-form__step-content">
    <div class="donation-form__heading">
      <Heading
        text="Unterstützen Sie regelmäßig die Arbeit der Frauenkirche"
        :hType="2"
        textAlign="center"
      />
    </div>
    <div class="donation-form__donate">
      <YourContribution
        :contributionValue="$store.state.donationProductForm.value"
      />
    </div>
    <div class="donation-form__regular">
      <div
        class="donation-form__regular-item"
        v-for="checkbox in $store.state.donationProductForm.regularVariants"
        :key="checkbox.id"
      >
        <RadioPanel :inputValue="checkbox.id" v-model="selectedOptions">
          <span v-html="checkbox.label"></span>
        </RadioPanel>
      </div>
    </div>

    <div class="donation-form__buttons">
      <Button @click="nextStep(2)">
        <span v-if="globalSettings">{{
          globalSettings['button_names']['next_step']
        }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading.vue';
import Button from '@/components/Button/Button.vue';
import RadioPanel from '@/components/RadioPanel/RadioPanel.vue';
import YourContribution from '@/components/YourContribution/YourContribution';

export default {
  name: 'DonationFormStep1',
  components: {
    YourContribution,
    Heading,
    Button,
    RadioPanel,
  },
  data() {
    return {
      selectedOptions: this.$store.state.donationProductForm.formData.regular,
    };
  },
  watch: {
    selectedOptions(val) {
      this.$store.commit('donationProductForm/setFormDataRegular', val);
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    nextStep(index) {
      this.$store.commit('donationProductForm/setPassedStep', index - 1);
      this.$store.commit('donationProductForm/setCurrentStep', index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
