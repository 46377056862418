<template lang="html">
  <div class="donation-form__step-content">
    <FormPaymentMethod
      v-if="$store.state.donationProductForm.value"
      :donateValue="$store.state.donationProductForm.value"
      @chooseMethod="nextStep"
      @comeBack="prevStep"
    />
  </div>
</template>

<script>
import FormPaymentMethod from '@/components/Form/FormPaymentMethod.vue';

export default {
  name: 'DonationFormStep3',
  components: {
    FormPaymentMethod,
  },
  computed: {
    formData() {
      return this.$store.state.donationPayment.formData;
    },
    currentProduct() {
      return this.$store.state.donationProducts.currentProduct;
    },
    selectedCategory() {
      return this.$store.state.donationProductForm.selectedCategory;
    },
  },
  methods: {
    nextStep() {
      const params = {
        donation: {
          donation_product: this.$store.state.donationPayment.postId,
          // donation_category: {
          //   0: 7,
          //   1: 2,
          // },
          donation_amount: this.$store.state.donationPayment.amount,
          recurring_payments:
            this.$store.state.donationProductForm.formData.regular,
        },
        postType: 'donations',
      };

      if (
        this.currentProduct &&
        this.currentProduct.custom_amount.value === 'yes' &&
        this.selectedCategory
      ) {
        params.donation.donation_category = {
          0: this.selectedCategory,
        };
      }

      this.$store.dispatch('donationPayment/pay', params);
    },
    prevStep() {
      this.$store.commit(
        'donationProductForm/setCurrentStep',
        this.$store.state.donationProductForm.currentStep - 1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
