<template lang="html">
  <div class="donated-product">
    <div class="donated-product__heading">
      <Heading
        textAlign="center"
        text="Wir haben Ihre Spende erhalten"
        :hType="1"
      />
    </div>
    <div class="donated-product__price">
      <YourContribution
        :contributionValue="$store.state.donationProductForm.value"
      />
    </div>
    <div class="donated-product__description">Damit unterstützen Sie:</div>
    <div class="donated-product__product" v-if="donatedProduct">
      <ImageWithLabel
        :label="donatedProduct.title"
        :imageUrl="donatedProduct.picture.sizes['medium_large']"
      />
    </div>
    <div class="donated-product__button">
      <Button v-if="globalSettings" @click="nextStep">
        <span v-if="globalSettings">{{
          globalSettings['button_names']['back_to_the_donation_page']
        }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading';
import YourContribution from '@/components/YourContribution/YourContribution';
import Button from '@/components/Button/Button';
import ImageWithLabel from '@/components/ImageWithLabel/ImageWithLabel';
export default {
  name: 'DonatedProduct',
  components: { ImageWithLabel, Button, YourContribution, Heading },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    donatedProduct() {
      const donationProducts =
        this.$store.state.donationProducts.donationProducts;
      const postId = this.$store.state.donationPayment.postId;
      let product;
      if (donationProducts) {
        product = donationProducts.find(
          ({ ID }) => String(ID) === String(postId)
        );
      }
      return product || null;
    },
  },
  created() {
    this.$store.dispatch('donationProducts/fetchDonationProducts');
  },
  beforeUnmount() {
    // this.$store.commit('donationProducts/setDonationProducts', []);
  },
  methods: {
    nextStep() {
      // this.$store.dispatch('donationProductForm/reset');
      // this.$store.dispatch('donationProducts/fetchDonationProducts');
      this.$store.dispatch('donationProductForm/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
