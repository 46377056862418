<template lang="html">
  <div class="image-with-label">
    <div class="image-with-label__container">
      <div v-if="label" class="image-with-label__label">{{ label }}</div>
      <img class="image-with-label__image" :src="imageUrl" :alt="label" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
